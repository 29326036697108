import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import { buildI18nProvider, RaEnterpriseTranslationMessages, defaultMessages } from '@react-admin/ra-enterprise';
import { raRelationshipsLanguageEnglish, raRelationshipsLanguageFrench } from '@react-admin/ra-relationships';

const resources = {
  en: {
    resources: {
      subjectreviews: { name: 'Review |||| Reviews' },
      trends: { name: 'Trend |||| Trends' },
      demo: { name: 'Demo' },
    }
  },
  fr: {
  },
};

const messages: Record<string,RaEnterpriseTranslationMessages> = {
  en: {
    ...defaultMessages.en,
    ...englishMessages,
    ...raRelationshipsLanguageEnglish,
    ...resources.en,
    errors: {
      parsingUrl: 'Encountered an error parsing URL',
    }
  },
  fr: {
    ...defaultMessages.fr,
    ...frenchMessages,
    ...raRelationshipsLanguageFrench,
    ...resources.fr,
  },
};

export const languages = [
  { locale: 'en', name: 'English' },
  { locale: 'fr', name: 'Français' },
];

export const defaultLanguage = languages[ 0 ];

export const i18nProvider = buildI18nProvider( messages, defaultLanguage.locale );

export default i18nProvider;
