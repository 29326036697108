import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BarDatum, BarLegendProps, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { AutocompleteArrayInput, Datagrid, DataProviderContext, DateField, Filter, Identifier, List, ListProps, Loading, ReferenceField, ReferenceInput, SelectArrayInput, SimpleShowLayout, TextField, useGetMany } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { apiUrl, httpClient } from './DataProvider';

export { default as SubjectReviewIcon } from '@mui/icons-material/StarHalf';

const SubjectReviewFilters: FC = props => {
  const [ options, setOptions ] = useState<Record<string,{id:string}[]>>();

  useEffect( () => { ( async () => {
    const url = new URL( `${apiUrl}/subjects/search` );
    const response = await httpClient( url.href );
    if( response.status !== 200 ) return;
    const result = _.mapValues( response.json, values => values.map( ( v:string ) => ( { id: v, name: v } ) ) )
    setOptions( result );
  } )() }, [] );

  return (
    <Filter {...props}>
      <ReferenceInput source='subject' reference='subjects'><AutocompleteArrayInput/></ReferenceInput>
      <AutocompleteArrayInput source='organization' choices={ options?.organizations } />
      <AutocompleteArrayInput source='kind' choices={ options?.kinds } />
      <ReferenceInput source='platform' reference='platforms'><AutocompleteArrayInput/></ReferenceInput>
      <SelectArrayInput source='rating' choices={ _.range( 1,6 ).map( n => ( { id: n, name: n } ) ) } />
    </Filter>
  );
};

export const SubjectReviewList: FC<ListProps> = props => (
  <List { ...props }
    filters= { <SubjectReviewFilters/> }
    exporter= { false }
    sort={{ field: 'publishedAt', order: 'DESC' }}
  >
    <Datagrid
      bulkActionButtons={ false }
      rowClick="expand"
      expand={ <SubjectReviewExpandShow /> }
    >
      <ReferenceField label="Subject" source="subject" reference="subjects" link={ false } >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Profile" source="name" />
      <TextField source="organization" />
      <TextField source="kind" />
      <ReferenceField label="Platform" source="profile" reference="profiles" link={ false } >
        <ReferenceField source="platform" reference="platforms" link={false} >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <DateField source="publishedAt" label="Date" sortByOrder='DESC' />
      <TextField source="rating" />
    </Datagrid>
  </List>
);

const SubjectReviewExpandShow: FC = () => (
  <SimpleShowLayout>
    <ReferenceField label="Resource" source="profile" reference="profiles" link={ false } >
      <TextField source="resource" />
    </ReferenceField>
    <TextField source="author" />
    <TextField source="body" label="Comment" />
    <TextField label="Displayed Address" source="address" />
    <TextField label="Displayed Phone" source="phone" />
  </SimpleShowLayout>
);

export type DurationType = 'months' | 'years';
export interface SubjectReviewChartProps {
  title?: string;
  compact?: boolean;
  duration: {
    type: DurationType,
    count: number,
  };
  subjects?: string[];
  organizations?: string[];
  kinds?: string[];
  platforms?: string[];
}
export const SubjectReviewChart: FC<SubjectReviewChartProps> = props => {
  const {
    title,
    compact: isCompact,
    duration,
    subjects,
    organizations,
    kinds,
    platforms,
  } = props;
  const height = isCompact ? 250 : 500;
  const dataProvider = useContext( DataProviderContext );
  const { data: subjectData } = useGetMany<{ id: Identifier, name: string }>( 'subjects', { ids: subjects } );
  const theme = useTheme();

  const navigate = useNavigate();

  const isFiltered = useMemo( () => !!_.find( props, p => Array.isArray( p ) && p.length > 0 ), [ props ] );

  const defaultTitle = useMemo( () => {
    if( organizations?.length ) {
      return organizations.length > 1 ? `${ organizations[ 0 ] }, et al.` : organizations[ 0 ];
    }
    if( subjectData?.length ) {
      const firstSubjectName = subjectData[0].name;
      return subjectData.length > 1 ? `${ firstSubjectName }, et al.` : firstSubjectName;
    }
    return platforms?.join( ', ' );
  }, [ organizations, platforms, subjectData ] );

  const chartQueryString = useMemo( () => {
    const query = new URLSearchParams( {
      durationCount: `${duration.count}`,
      durationType: duration.type,
    } );
    subjects?.length && query.append( 'subject', encodeURIComponent( subjects.join() ) );
    organizations?.length && query.append( 'organization', encodeURIComponent( organizations.join() ) );
    kinds?.length && query.append( 'kind', encodeURIComponent( kinds.join() ) );
    platforms?.length && query.append( 'platform', encodeURIComponent( platforms.join() ) );
    return query.toString();
  }, [ duration, subjects, organizations, kinds, platforms ] )

  const storedData = useMemo( () => {
    const data = localStorage.getItem( `ReputationChart_${chartQueryString}` );
    return data ? JSON.parse( data ) as BarDatum[] : undefined;
  }, [ chartQueryString ] );

  const chartQuery = useQuery<BarDatum[]>( {
    queryKey: [ 'ReputationChart', { duration, subjects, organizations, kinds, platforms } ],
    queryFn: async () => {
      if( !isFiltered ) return [];
      const response = await dataProvider?.fetchJson( `${dataProvider.apiUrl}/subjectreviews/trends?${ chartQueryString }` );
      if( response.status >= 500 ) throw new Error();
      const data = response.json;
      try {
        localStorage.setItem( `ReputationChart_${chartQueryString}`, JSON.stringify( data ) );
      }
      catch { /* ignore errors setting local storage */ }
      return data;
    },
    placeholderData: storedData,
    retry: 3,
  } );

  const handleClick = ( _datum: ComputedDatum<BarDatum> ) => {
    const filter: Record<string, string[]> = {};
    if( subjects?.length ) filter.subject = subjects;
    if( organizations?.length ) filter.organization = organizations;
    if( kinds?.length ) filter.kind = kinds;
    if( platforms?.length ) filter.platform = platforms;
    navigate( { pathname: '/subjectreviews', search: new URLSearchParams( {
      filter: JSON.stringify( filter ),
      displayedFilters: JSON.stringify( _.mapValues( filter, v => !!v ) ),
    } ).toString() } );
  }

  const chartData = useMemo<BarDatum[]>(
    () => chartQuery.isSuccess ? chartQuery.data : storedData || [],
    [ chartQuery.data, chartQuery.isSuccess, storedData ]
  );

  const hasAngledLabels = useMemo( () => duration.count > 6 , [ duration.count ] );

  const hasData = useMemo<boolean>( () => {
    if( !chartData ) return false;
    return !!chartData.find( datum => {
      for( const key in datum ) {
        if( key == 'id' ) continue;
        if( datum[ key] ) return true;
      }
      return false;
    } )

  }, [ chartData ] );

  const Message: FC<{ children: React.ReactNode }> = ( props ) => (
    <Typography
      variant='body1'
      margin='10px'
    >{ props.children }</Typography>
  );
  if( chartQuery.isLoading ) return (
    <Box height={ height } marginY={ 1 }>
      <Loading
        loadingPrimary=''
        loadingSecondary=''
        sx={{
          '@media (min-width: 0)': {
            marginTop: 0,
            height: '100%',
          }
        }}
      />
    </Box>
  );
  if( !isFiltered || !chartData.length ) return <Box height={ height } marginY={ 1 }><Message>Unable to load chart data.</Message></Box>;

  const legend: BarLegendProps = {
    anchor: 'top',
    direction: 'row',
    justify: false,
    translateY: -40,
    itemWidth: 80,
    itemHeight: 20,
    itemsSpacing: 0,
    dataFrom: 'keys',
  };

  return (
    <Box
      height={ height }
      marginY={ 1 }
    >
      <ResponsiveBar
        data={ chartData }
        indexBy='id'
        keys={ [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ] }

        margin={ isCompact ?
          { bottom: 30 } :
          { top: 50, right: 20, bottom: hasAngledLabels ? 75 : 60, left: 65 }
        }
        theme={ {
          background: theme.palette?.background?.default,
          axis: {
            legend: { text: { fontSize: isCompact ? 16 : 18 } },
            ticks: { text: { fontSize: isCompact ? 0 : 14 } },
          },
          labels: { text: { fill: '#333' } },
          legends: { text: { fontSize: 14 } },
          text: { color: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
          tooltip: {
            container: {
              backgroundColor: theme.palette?.background?.default,
              color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
            },
          },
        } }

        groupMode='stacked'
        padding={ 0.25 }

        minValue={ 0 }
        maxValue={ hasData ? 'auto' : 5 }

        colors={ { scheme: 'red_yellow_green', size: 5 } }

        enableLabel={ false }

        axisLeft={ isCompact ? null : { legend: 'New Ratings Count', tickSize: 5, tickPadding: 5, tickValues: 5, legendOffset: -45, legendPosition: 'middle' } }
        axisBottom={ {
          legend: title || defaultTitle,
          legendPosition: 'middle',
          legendOffset: isCompact ? 20 : ( hasAngledLabels ? 55 : 40 ),
          tickRotation: isCompact ? -60 : ( hasAngledLabels ? -33 : 0 ),
          tickSize: isCompact ? 0 : 5,
        } }

        legends={ isCompact ? undefined : [ legend ] }
        isInteractive={ !isCompact }

        onClick={ handleClick }
      />
    </Box>
  );
};
