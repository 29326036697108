import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';
import _, { capitalize } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { ArrayInput, Create, CreateProps, Datagrid, DateField, DeleteButton, Filter, FunctionField, Identifier, List, ListProps, RaRecord, ReferenceArrayField, ReferenceField, ReferenceManyField, SelectInput, Show, ShowProps, SimpleForm, SimpleFormIterator, SimpleShowLayout, SingleFieldList, TextField, TextInput, WithListContext } from 'react-admin';

import { apiUrl, httpClient } from './DataProvider';
import MyUrlField from './MyUrlField';
import { lookupProfile, ProfileCharts } from './Profiles';
import { SubjectReviewChart } from './SubjectReviews';

const SubjectFilters: FC = props => {
  const [ options, setOptions ] = useState<Record<string,{id:string}[]>>();

  useEffect( () => { ( async () => {
    const url = new URL( `${apiUrl}/subjects/search` );
    const response = await httpClient( url.href );
    if( response.status !== 200 ) return;
    const result = _.mapValues( response.json, values => values.map( ( v:string ) => ( { id: v, name: v } ) ) )
    setOptions( result );
  } )() }, [] );

  if( !options ) return null;
  return (
    <Filter {...props}>
      <SelectInput source='name' choices={ options.names } />
      <SelectInput source='organization' choices={ options.organizations } />
      <SelectInput source='kind' choices={ options.kinds } />
    </Filter>
  );
};

export const SubjectList: FC<ListProps> = props => (
  <List { ...props }
    exporter={ false }
    filters={ <SubjectFilters /> }
    // actions={ undefined } // not sure why but this fixes an error "pattern.endsWith is not a function"
  >
    <Datagrid
      bulkActionButtons={ false }
      // rowClick='expand'
      expand={ <SubjectExpandShow /> }
    >
      <TextField source='name' />
      <TextField source='reference' />
      <TextField source='organization' />
      <TextField source='kind' />
      <DateField source='createdAt' showTime/>
      <DeleteButton />
    </Datagrid>
  </List>
);

interface SummaryFieldParams {
  source: string;
  label?: string;
}
const SummaryField: FC<SummaryFieldParams> = params => (
  <ReferenceManyField
    label={ params.label ?? capitalize( params.source ) }
    reference='summaries' target='profile'
    sort={{ field: 'createdAt', order: 'DESC' }}
    page={1} perPage={1}
  >
    <SingleFieldList linkType={ false }>
      <TextField source={ params.source } />
    </SingleFieldList>
  </ReferenceManyField>
);
const SubjectExpandShow: FC = () => (
  <ReferenceArrayField reference='profiles' source='profiles' >
    <Datagrid bulkActionButtons={ false } >
      <ReferenceField reference='platforms' source='platform' link={false} >
        <TextField source='name' />
      </ReferenceField>
      <SummaryField source='name' label='Display Name' />
      <SummaryField source='rating' label='Average Rating' />
      <SummaryField source='count' label='Review Count' />
      <MyUrlField source='url' label='External Link' />
    </Datagrid>
  </ReferenceArrayField>
);

export const SubjectCreate: FC<CreateProps> = props => {
  const [ validatedProfiles, setValidatedProfiles ] = useState<{ url: string, profileId: Identifier }[]>( [] );

  const validateProfileUrl = async ( url: string ): Promise<string|undefined> => {
    if( !url ) return undefined;
    if( validatedProfiles.find( p => p.url === url ) ) return undefined;
    const profileId = await lookupProfile( url );
    if( profileId ) {
      setValidatedProfiles( prev => prev.concat( { url, profileId } ) );
      return undefined;
    } else return 'Failed to validate URL';
  }

  const transform = async ( data: RaRecord ) => {
    const profiles: Identifier[] = await Promise.all( data.profiles.map( async ( { profileHref }: { profileHref: string } ) => {
      const validatedProfileId = validatedProfiles.find( profile => profile.url === profileHref )?.profileId;
      return validatedProfileId || await lookupProfile( profileHref );
    } ) );
    return {
      ...data,
      profiles,
    };
  };

  return (
    <Create {...props} transform={ transform }>
      <SimpleForm>
        <TextInput source='name' />
        <TextInput source='reference' />
        <TextInput source='organization' />
        <TextInput source='kind' />
        <ArrayInput source='profiles' >
          <SimpleFormIterator>
            <TextInput label='Profile URL' source='profileHref' validate={ validateProfileUrl } />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const SubjectShow: FC<ShowProps> = props => {
  return (
    <Show { ...props }>
      <SimpleShowLayout>
        <TextField source='name' />
        <TextField source='reference' />
        <TextField source='organization' />
        <TextField source='kind' />
        <FunctionField label='Aggregate' render={ record => (
          <>
            <Box id='monthly_aggregate' width={ 500 } boxShadow={ 3 } borderRadius={ 2 } margin={ 1 } display='inline-block' overflow='clip'>
              <SubjectReviewChart title='Monthly (Aggregated)' subjects={[ record.id ]} duration={ { type: 'months', count: 6 } } />
            </Box>
            <Box id='yearly_aggregate' width={ 500 } boxShadow={ 3 } borderRadius={ 2 } margin={ 1 } display='inline-block' overflow='clip'>
              <SubjectReviewChart title='Yearly (Aggregated)' subjects={[ record.id ]} duration={ { type: 'years', count: 5 } } />
            </Box>
          </>
        ) } />
        <ReferenceArrayField reference='profiles' source='profiles'>
          <WithListContext  render={ ( { data } ) => (
            <Stack>
              { data?.map( record => (

                <div
                  id={ `profile_${ record.id }` }
                  key={ record.id }
                  style={{
                    margin: 1,
                    width: '1200px',
                  }}
                >
                  <Accordion
                    defaultExpanded
                  >
                    <AccordionSummary expandIcon={ <ExpandMore /> } >
                      <Typography>{ record.id }</Typography>
                    </AccordionSummary>
                    <AccordionDetails id={ `${ record.id }` }>
                      <SimpleShowLayout record={ record }>
                        <SummaryField source='name' label='Display Name' />
                        <ReferenceField reference='platforms' source='platform' link={ false }>
                          <TextField source='name' />
                        </ReferenceField>
                        <SummaryField source='rating' label='Average Rating' />
                        <SummaryField source='count' label='Review Count' />
                        <ProfileCharts profileId={ record.id } />
                      </SimpleShowLayout>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ) ) }
            </Stack>
          ) } />

        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
