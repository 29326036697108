import { FC } from 'react';
import { Create, CreateProps, Datagrid, Edit, EditProps, FunctionField, List, ListProps, SimpleForm, TextField, TextInput, UrlField } from 'react-admin';

export { default as PlatformIcon } from '@mui/icons-material/RateReview';

export const PlatformList: FC<ListProps> = props => (
  <List { ...props }
    exporter={ false }
  >
    <Datagrid
      bulkActionButtons={ false }
      rowClick="edit"
    >
      <FunctionField source="id" sortable={ false }
        render={ ( record ) => ( record?.id && <img src={ `${ record.id }.png` } style={ { maxHeight: 30, maxWidth: 150, display: 'block' } } alt='' /> ) }
      />
      <TextField source="name" />
      <TextField source="id" />
      <UrlField source="profileTemplateUrl" />
      <UrlField source="referralTemplateUrl" />
    </Datagrid>
  </List>
);

export const PlatformEdit: FC<EditProps> = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="profileTemplateUrl" />
      <TextInput source="referralTemplateUrl" />
    </SimpleForm>
  </Edit>
);

export const PlatformCreate: FC<CreateProps> = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="profileTemplateUrl" />
      <TextInput source="referralTemplateUrl" />
    </SimpleForm>
  </Create>
);
