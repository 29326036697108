import _ from 'lodash';
import { FC } from 'react';
import { AutocompleteArrayInput, Datagrid, DateField, Filter, List, ListProps, ReferenceField, ReferenceInput, ReferenceManyField, SelectArrayInput, SimpleShowLayout, SingleFieldList, TextField } from 'react-admin';

export { default as ReviewIcon } from '@mui/icons-material/StarHalf';

const ReviewFilters: FC = ( props ) => {
  return (
    <Filter {...props}>
      <ReferenceInput source='platform' reference='platforms'><AutocompleteArrayInput/></ReferenceInput>
      <SelectArrayInput source='rating' choices={ _.range( 1,6 ).map( n => ( { id: n, name: n } ) ) } />
    </Filter>
  );
}

export const ReviewList: FC<ListProps> = props => (
  <List { ...props }
    actions={ false }
    filters={ <ReviewFilters/> }
    sort={{ field: 'publishedAt', order: 'DESC' }}
  >
    <Datagrid rowClick="expand" expand={ <ReviewExpandShow /> } >
      <TextField source="rating" />
      <DateField source="publishedAt" label="Date" />
      <ReferenceManyField
        source="profile"
        reference="summaries"
        target='profile'
        sort={ { field: 'createdAt', order: 'DESC' } }
        page={ 1 } perPage={ 1 }
      >
        <SingleFieldList linkType={ false }>
          <TextField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <ReferenceField label="Platform" source="profile" reference="profiles" link={ false } >
        <ReferenceField source="platform" reference="platforms" link={false} >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <TextField source="author" />
    </Datagrid>
  </List>
);

const ReviewExpandShow: FC = () => (
  <SimpleShowLayout>
    <TextField source="body" label="Comment" />
    {/* <TextField source="response.body" label="Response" /> */}
    {/* <DateField source="response.publishedAt" label="Response Date" /> */}
  </SimpleShowLayout>
);
