import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { FC, useContext, useMemo } from 'react';
import { DataProviderContext, Identifier, Loading, ReferenceField, ReferenceOneField, Show, ShowProps, SimpleShowLayout, TextField, UrlField, WithRecord } from 'react-admin';
import { useQuery } from '@tanstack/react-query';

import { apiUrl, httpClient } from './DataProvider';

export const lookupProfile = async ( url: string ): Promise<Identifier|undefined> => {
  const query = new URLSearchParams( { url } )
  const response = await httpClient( `${apiUrl}/profiles/lookup?${query}` );
  return response?.json?.id;
};

interface SummaryFieldProps {
  label?: string;
  source: string;
}
const SummaryField: FC<SummaryFieldProps> = ( props ) => {
  return (
    <ReferenceOneField label={ props.label } reference='summaries' target='profile' sortBy='createdAt'>
      <TextField source={ props.source } />
    </ReferenceOneField>
  );
}

export const ProfileShow: FC<ShowProps> = ( props ) => {
  return (
    <Show { ...props }>
      <SimpleShowLayout>
        <SummaryField source='name' label='Name' />
        <ReferenceField source='platform' reference='platforms' link={ false }>
          <TextField source='name' />
        </ReferenceField>
        <UrlField source='url' />
        <SummaryField source='count' label='Rating Count' />
        <SummaryField source='rating' label='Average Rating' />
        <WithRecord render={ ( record ) =>  <ProfileCharts profileId={ record.id } /> } />
      </SimpleShowLayout>
    </Show>
  );
}
export const ProfileCharts: FC<{ profileId: Identifier }> = ( { profileId } ) => {
  return (
    <>
      <Box id='monthChart' width={ 500 } boxShadow={ 3 } borderRadius={ 2 } margin={ 1 } display='inline-block' overflow='clip'>
        <ProfileReviewChart title='Monthly' profileId={ profileId } duration={ { type: 'months', count: 6 } } />
      </Box>
      <Box id='yearChart' width={ 500 } boxShadow={ 3 } borderRadius={ 2 } margin={ 1 } display='inline-block' overflow='clip'>
        <ProfileReviewChart title='Yearly' profileId={ profileId } duration={ { type: 'years', count: 5 } } />
      </Box>
    </>
  );
}

export type DurationType = 'months' | 'years';
export interface ProfileReviewChartProps {
  profileId: Identifier;
  duration: {
    type: DurationType;
    count: number;
  };
  height?: number;
  maxWidth?: number;
  refreshMillis?: number;
  title?: string;
}
export const ProfileReviewChart: FC<ProfileReviewChartProps> = props => {
  const {
    profileId,
    duration,
    height = 500,
    refreshMillis,
    title,
  } = props;
  const dataProvider = useContext( DataProviderContext );
  const theme = useTheme();

  const query = useMemo( () => {
    return new URLSearchParams( {
      durationCount: `${duration.count}`,
      durationType: duration.type,
    } ).toString();
  }, [ duration.count, duration.type ] );

  const storageKey = useMemo( () => `ProfileChart_${profileId}_${duration.type}_${duration.count}`, [ duration.count, duration.type, profileId ] );

  const storedData = useMemo( () => {
    const data = localStorage.getItem( storageKey );
    return data ? JSON.parse( data ) : undefined;
  }, [ storageKey ] );

  const chartQuery = useQuery<BarDatum[]>( {
    queryKey: [ 'ProfileChart', { profileId, duration } ],
    queryFn: async () => {
      const response = await dataProvider?.fetchJson( `${dataProvider.apiUrl}/profiles/${profileId}/trends?${query}` );
      if( response.status >= 500 ) throw new Error();
      const data = response.json;
      try {
        localStorage.setItem( storageKey, JSON.stringify( data ) );
      }
      catch { /* ignore errors setting local storage */ }
      return data;
    },
    placeholderData: storedData,
    retry: 3,
    refetchInterval: refreshMillis,
  } );

  const hasAngledLabels = useMemo( () => duration.count > 6 , [ duration.count ] );

  if( chartQuery.isLoading ) return (
    <Box height={ height }>
      <Loading
        loadingPrimary=''
        loadingSecondary=''
        sx={{
          '@media (min-width: 0)': {
            marginTop: 0,
            height: '100%',
          }
        }}
      />
    </Box>
  );
  if( !chartQuery.data?.length ) return (
    <Box height={ height }>
      <Typography
        variant='body1'
        margin='10px'
      >Unable to load chart data.</Typography>
    </Box>
  );
  return (
    <Box
      height={ height }
    >
      <ResponsiveBar
        data={ chartQuery.data }
        indexBy='id'
        keys={ [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ] }

        margin={ { top: 50, right: 20, bottom: hasAngledLabels ? 75 : 60, left: 65 } }

        theme={ {
          background: theme.palette?.background?.default,
          axis: {
            legend: { text: { fontSize: 18 } },
            ticks: { text: { fontSize: 14 } },
          },
          labels: { text: { fill: '#333' } },
          legends: { text: { fontSize: 14 } },
          text: { color: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
          tooltip: {
            container: {
              backgroundColor: theme.palette?.background?.default,
              color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
            },
          },
        } }

        groupMode='stacked'
        padding={ 0.25 }

        colors={ { scheme: 'red_yellow_green', size: 5 } }

        enableLabel={ false }

        axisLeft={ { legend: 'New Ratings Count', tickSize: 5, tickPadding: 5, tickValues: 5, legendOffset: -45, legendPosition: 'middle' } }
        axisBottom={ {
          legend: title,
          legendPosition: 'middle',
          legendOffset: hasAngledLabels ? 55 : 40,
          tickRotation: hasAngledLabels ? -33 : 0,
          tickSize: 5,
        } }

        legends={ [ {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemsSpacing: 0,
          dataFrom: 'keys',
        } ] }
      />
    </Box>
  );
}
