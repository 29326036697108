import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import get from 'lodash/get';
import { FC, memo } from 'react';
import { useRecordContext, UrlFieldProps } from 'react-admin';


const useStyles = makeStyles( {
  link: {
    textDecoration: 'none',
  },
  icon: {
    width: '0.5em',
    height: '0.5em',
    paddingLeft: 2,
  },
} );

export const prettyUrlLabel = ( url: string ): string => {
  return url
    .replace( /http(s)?:\/\/(www.)?/, '' )
    .replace( /\/.*/, '' )
    .toLowerCase()
    .split( '.' )
    .slice( -2 )
    .join( '.' );
}

export const MyUrlField: FC<UrlFieldProps> = memo<UrlFieldProps>( props => {
  const { source } = props;
  const record = useRecordContext( props );
  const value = get( record, source || '', null );
  const classes = useStyles();
  if( !value ) {
    return null;
  }

  return (
    <Link
      className={ classes.link }
      href={ value }
      rel="noopener"
      target="_blank"
      onClick={ e => { e.stopPropagation(); } }
    >
      <span style={ { whiteSpace: 'nowrap' } } >
        { prettyUrlLabel( value ) }
        <LaunchIcon className={ classes.icon } fontSize="small" />
      </span>
    </Link>
  );
} );

MyUrlField.displayName = 'MyUrlField';

export default MyUrlField;
