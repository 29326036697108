import { AddCircle as AddCircleIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Autocomplete, Box, Card, CardContent, IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import _, { throttle } from 'lodash';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { RaRecord, Title, useGetList } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { apiUrl, httpClient } from './DataProvider';

import { DurationType, SubjectReviewChart } from './SubjectReviews';

export { default as TrendPageIcon } from '@mui/icons-material/BarChart';

const getEmptyChartValues = (): TrendChartValues => ( {
  subjects: [],
  organizations: [],
  kinds: [],
  platforms: [],
  duration: { type: 'months', count: 6 },
} );

const useInitialChartData = (): TrendChartValues[] => {
  const [ searchParams ] = useSearchParams();
  const queryChartValues = searchParams.get( 'chart' );
  if( queryChartValues ) {
    const chartData = JSON.parse( queryChartValues );
    return [].concat( chartData ).map( ( chart: TrendChartValues ) => ( { ...getEmptyChartValues(), ...chart } ) )
  }
  const storedChartValues = localStorage.getItem( 'trendChartValues' );
  if( storedChartValues ) {
    return JSON.parse( storedChartValues );
  }
  return [ getEmptyChartValues() ];
}

export const TrendPage: FC = ( props ) => {
  const initialChart = useInitialChartData();
  const [ chartValues, setChartValues ] = useState<TrendChartValues[]>( initialChart );
  const [ searchParams, setSearchParams ] = useSearchParams();

  const addChart = () => {
    setChartValues( chartValues.concat( getEmptyChartValues() ) )
  }

  useEffect( () => {
    const chartJson = JSON.stringify( chartValues );
    localStorage.setItem( 'trendChartValues', chartJson );
    searchParams.set( 'chart', chartJson );
    setSearchParams( searchParams );
  }, [ chartValues, searchParams, setSearchParams ] )

  return (
    <Card {...props} >
      <Title title='Trends' />
      <CardContent>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat( auto-fill, minmax( 500px, 1fr )',
            gridTemplateRows: 'auto',
          }}
        >
          { _.map( chartValues, ( values, index ) => (
            <div key={ index } style={{ position: 'relative' }}>
              <DynamicTrendChart
                values={ values }
                onChange={ values => {
                  const newChartValues = chartValues.slice();
                  newChartValues[index] = values;
                  setChartValues( newChartValues );
                } }
              />
              { chartValues.length > 1 ?
                (
                  <Tooltip title='Remove Chart' placement='left'>
                    <IconButton
                      sx={{ position: 'absolute', right: 5, top: 0 }}
                      onClick={ () => {
                        const newChartValues = chartValues.slice();
                        newChartValues.splice( index, 1 );
                        setChartValues( newChartValues );
                      } }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )
                : null }
            </div>
          ) ) }
        </div>
        <Tooltip title='Add Chart' placement='right' >
          <IconButton color='primary' onClick={ addChart } >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

export interface TrendChartValues {
  title?: string;
  subjects: string[];
  organizations: string[];
  kinds: string[];
  platforms: string[];
  duration: {
    type: DurationType;
    count: number;
  }
}
export interface TrendChartParams {
  values: TrendChartValues;
  onChange: ( newValues: TrendChartValues ) => void;
}
export interface NamedRecord extends RaRecord {
  name: string;
}
export const DynamicTrendChart: FC<TrendChartParams> = ( params ) => {
  const {
    title,
    subjects,
    organizations,
    kinds,
    platforms,
    duration,
  } = params.values;

  const [ titleValue, setTitleValue ] = useState( title );

  const [ organizationInputValue, setOrganizationInputValue ] = useState<string>( '' );
  const [ organizationOptions, setOrganizationOptions ] = useState<readonly string[]>( [] );
  const [ kindInputValue, setKindInputValue ] = useState<string>( '' );
  const [ kindOptions, setKindOptions ] = useState<readonly string[]>( [] );

  const [ subjectInputValue, setSubjectInputValue ] = useState<string>( '' );
  const { data: subjectData } = useGetList<NamedRecord>( 'subjects', { filter: { q: subjectInputValue } } );
  const [ selectedSubjects, setSelectedSubjects ] = useState<RaRecord[]>( [] );
  const [ platformInputValue, setPlatformInputValue ] = useState<string>( '' );
  const { data: platformData } = useGetList<NamedRecord>( 'platforms', { filter: { q: platformInputValue } } );
  const [ selectedPlatforms, setSelectedPlatforms ] = useState<RaRecord[]>( [] );

  const fetchOptions = useMemo( () => throttle( async ( input: Record<string,string> ): Promise<Record<string,string[]>> => {
    const url = new URL( `${apiUrl}/subjects/search` );
    url.search = new URLSearchParams( _.mapValues( input, v => encodeURI( v ) ) ).toString()
    const response = await httpClient( url.href );
    if( response.status !== 200 ) return {};
    return response.json;
  }, 200, { trailing: true } ), [] );

  const saveTitle = useMemo( () => _.debounce( ( newTitle: string ) => {
    params.onChange( { ...params.values, title: newTitle } );
    console.log( newTitle );
  }, 5000, { trailing: true } ), [ params ] );
  const handleTitleChange = ( event: ChangeEvent<HTMLInputElement> ) => {
    const newTitle = event.target.value;
    setTitleValue( newTitle );
    saveTitle( newTitle );
  }
  const handleSubjectChange = ( _event: unknown, newValue: string[] ) => {
    setSelectedSubjects( _( selectedSubjects )
      .concat( _.compact( newValue.map( v => subjectData?.find( s => s.id === v ) ) ) )
      .uniq()
      .value()
    );
    params.onChange( { ...params.values, subjects: newValue } );
  }
  const handleOrganizationChange = ( _event: unknown, newValue: string[] ) => {
    params.onChange( { ...params.values, organizations: newValue } );
  }
  const handleKindChange = ( _event: unknown, newValue: string[] ) => {
    params.onChange( { ...params.values, kinds: newValue } );
  }
  const handlePlatformChange = ( _event: unknown, newValue: string[] ) => {
    setSelectedPlatforms( _( selectedPlatforms )
      .concat( _.compact( newValue.map( v => platformData?.find( p => p.id === v ) ) ) )
      .uniq()
      .value()
    );
    params.onChange( { ...params.values, platforms: newValue } );
  }
  const handleDurationCountChange = ( event: ChangeEvent<HTMLInputElement> ) => {
    params.onChange( {
      ...params.values,
      duration: { ...duration, count: parseInt( event.target.value ) },
    } )
  }
  const handleDurationTypeChange = ( _event: unknown, newValue: DurationType ) => {
    params.onChange( {
      ...params.values,
      duration: { ...duration, type: newValue },
    } )
  }

  useEffect( () => {
    ( async () => {
      const query = _.pickBy( {
        organization: organizationInputValue,
        kind: kindInputValue,
      } );
      const options = await fetchOptions( query );
      if( options?.organizations ) setOrganizationOptions( _.uniq( [ ...options.organizations, ...organizations ] ) );
      if( options?.kinds ) setKindOptions( _.uniq( [ ...options.kinds, ...kinds ] ) );
    } )();
  }, [ fetchOptions, kindInputValue, kinds, organizationInputValue, organizations ] );

  return (
    <div
      style={ {
        margin: 4,
        border: '2px solid grey',
        borderRadius: '0.5em',
      } }
    >
      <form
        autoComplete='off'
        style={{
          margin: '0 40px 0 5px',
          gridTemplateColumns: 'repeat( auto-fit, 1fr )',
          columnGap: '5px',
        }}
      >
        <Tooltip
          title=''
          placement='top'
        >
          <TextField
            label='Title'
            value={ titleValue }
            onChange={ handleTitleChange }
            fullWidth
          />
        </Tooltip>
        <Tooltip
          title='View data for specific Locations and/or Practitioners that have received reviews.'
          placement='top'
        >
          <Autocomplete
            value={ subjects }
            inputValue={ subjectInputValue }
            options={ subjectData?.map( s => s.id.toString() ) ?? [] }
            getOptionLabel={ option => _.concat( selectedSubjects, subjectData ?? [] ).find( s => s.id === option )?.name ?? option }
            onChange={ handleSubjectChange }
            onInputChange={ ( _event, newInputValue ) => { setSubjectInputValue( newInputValue ) } }
            multiple
            isOptionEqualToValue={ ( option, value ) => option === value }
            renderInput={( params ) => (
              <TextField
                {...params }
                label='Subject'
              />
            ) }
            filterOptions={ ( x ) => x }
          />
        </Tooltip>
        <Tooltip
          title='View data for groups of multiple Locations and/or Practitioners.'
          placement='top'
        >
          <Autocomplete
            value={ organizations }
            inputValue={ organizationInputValue }
            options={ organizationOptions }
            onChange={ handleOrganizationChange }
            onInputChange={ ( _event, newInputValue ) => { setOrganizationInputValue( newInputValue ) } }
            multiple
            isOptionEqualToValue={ ( option, value ) => option === value }
            renderInput={( params ) => (
              <TextField
                {...params }
                label='Organization'
              />
            ) }
            filterOptions={ ( x ) => x }
          />
        </Tooltip>
        <Tooltip
          title=''
          placement='top'
        >
          <Autocomplete
            value={ kinds }
            inputValue={ kindInputValue }
            options={ kindOptions }
            onChange={ handleKindChange }
            onInputChange={ ( _event, newInputValue ) => { setKindInputValue( newInputValue ) } }
            multiple
            isOptionEqualToValue={ ( option, value ) => option === value }
            renderInput={( params ) => (
              <TextField
                {...params }
                label='Kind'
              />
            ) }
            filterOptions={ ( x ) => x }
          />
        </Tooltip>
        <Tooltip
          title='Limit results to specific review sites.'
          placement='top'
        >
          <Autocomplete
            value={ platforms }
            inputValue={ platformInputValue }
            options={ platformData?.map( p => p.id.toString() ) ?? [] }
            getOptionLabel={ option => _.concat( selectedPlatforms, platformData ?? [] ).find( p => p.id === option )?.name ?? option }
            onChange={ handlePlatformChange }
            onInputChange={ ( _event, newInputValue ) => { setPlatformInputValue( newInputValue ) } }
            multiple
            isOptionEqualToValue={ ( option, value ) => option === value }
            renderInput={( params ) => (
              <TextField
                {...params }
                label='Platform'
              />
            ) }
            filterOptions={ ( x ) => x }
          />
        </Tooltip>
      </form>
      <TextField
        label='Range'
        type='number'
        variant='outlined'
        value={ duration.count }
        onChange={ handleDurationCountChange }
        style={ { margin: '0.5em' } }
      />
      <ToggleButtonGroup value={ duration.type } onChange={ handleDurationTypeChange } exclusive>
        <ToggleButton value='years'>Years</ToggleButton>
        <ToggleButton value='months'>Months</ToggleButton>
      </ToggleButtonGroup>
      <Box width={ 500 } boxShadow={ 3 } borderRadius={ 2 } margin={ 1 }>
        <SubjectReviewChart
          title={ titleValue }
          subjects={ subjects }
          organizations={ organizations }
          kinds={ kinds }
          platforms={ platforms }
          duration={ duration }
        />
      </Box>
    </div>
  );
};
