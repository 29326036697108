import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataProviderContext, Loading, RaRecord } from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import { BarDatum, ResponsiveBar } from '@nivo/bar';

export const AggregateReportPage: FC = ( props ) => {
  const [ searchParams ] = useSearchParams();
  const references = useMemo( () => searchParams.get( 'references' )?.split( ',' ), [ searchParams ] );
  const organization = useMemo( () => searchParams.get( 'organization' ), [ searchParams ] );

  if( !references?.length || !organization ) return null;
  return (
    <Card {...props}>
      <CardContent>
        <Box id='monthly_aggregate' width={ 500 } display='inline-block'>
          <ReputationChart
            type='aggregate'
            organization={ organization }
            references={ references }
            durationType='months'
          />
        </Box>
        <Box id='yearly_aggregate' width={ 500 } display='inline-block'>
          <ReputationChart
            type='aggregate'
            organization={ organization }
            references={ references }
            durationType='years'
          />
        </Box>
        { references.map( reference => ( <>
          <Box id={ `monthly_${reference}` } width={ 500 } display='inline-block' >
            <ReputationChart
              type='subject'
              reference={ reference }
              organization={ organization }
              durationType='months'
            />
          </Box>
          <Box id={ `yearly_${reference}` } width={ 500 } display='inline-block' >
            <ReputationChart
              type='subject'
              reference={ reference }
              organization={ organization }
              durationType='years'
            />
          </Box>
        </> ) ) }
      </CardContent>
    </Card>
  );
}

export type ReputationChartProps = {
  durationType: 'months'| 'years',
  organization: string,
} & (
  {
    type: 'subject',
    reference: string,
  } | {
    type: 'aggregate',
    references: string[],
  }
)
export const ReputationChart: FC<ReputationChartProps> = props => {
  const {
    type,
    durationType,
    organization,
  } = props;
  const dataProvider = useContext( DataProviderContext );
  const theme = useTheme();
  const [ subject, setSubject ] = useState<RaRecord>();

  const height = 500;
  const durationCount = durationType == 'months' ? 6 : 5;

  useEffect( () => {
    ( async () => {
      if( type != 'subject' || !dataProvider ) return;
      const { data } = await dataProvider.getList( 'subjects', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: { reference: props.reference, organization },
      } );
      if( data?.length ) setSubject( data[ 0 ] );
    } )();
  }, [ dataProvider, props ] );

  const title = useMemo( () => {
    const durationLabel = durationType == 'months' ? 'Monthly' : 'Yearly';
    if( type == 'aggregate' ) return `${ durationLabel } - All Locations`;
    return `${ durationLabel } - ${ subject?.name }`;
  }, [ durationType, subject, type ] );

  const query = useMemo( () => {
    return new URLSearchParams( {
      durationCount: durationCount.toString(),
      durationType,
      organization: encodeURIComponent( organization ),
      reference: encodeURIComponent( type == 'subject' ? props.reference : props.references.join() ),
    } ).toString();
  }, [ durationType, props ] );

  // const storageKey = useMemo( () => {
  //   if( props.type == 'profile' ) return `ProfileChart_${ props.profileId }_${durationType}_${durationCount}`;
  //   return
  // }, [ durationCount, durationType, props ] );

  // const storedData = useMemo( () => {
  //   const data = localStorage.getItem( storageKey );
  //   return data ? JSON.parse( data ) : undefined;
  // }, [ storageKey ] );

  const queryKey = useMemo( () => {
    if( props.type == 'subject' ) return [ 'SubjectChart', { organization, reference: props.reference, durationType, durationCount } ];
    return [ 'AggregateChart', { organization, profileIds: props.references, durationType, durationCount } ];
  }, [ props ] );

  const chartQuery = useQuery<BarDatum[]>( {
    queryKey,
    queryFn: async () => {
      const response = await dataProvider?.fetchJson( `${dataProvider.apiUrl}/subjectreviews/trends?${query}` );
      if( response.status >= 500 ) throw new Error();
      const data = response.json;
      // try {
      //   localStorage.setItem( storageKey, JSON.stringify( data ) );
      // }
      // catch { /* ignore errors setting local storage */ }
      return data;
    },
    // placeholderData: storedData,
    retry: 3,
  } );

  const hasAngledLabels = useMemo( () => durationCount > 6 , [ durationCount ] );

  if( chartQuery.isLoading ) return (
    <Box height={ height }>
      <Loading
        loadingPrimary=''
        loadingSecondary=''
        sx={{
          '@media (min-width: 0)': {
            marginTop: 0,
            height: '100%',
          }
        }}
      />
    </Box>
  );
  if( !chartQuery.data?.length ) return (
    <Box height={ height }>
      <Typography
        variant='body1'
        margin='10px'
      >Unable to load chart data.</Typography>
    </Box>
  );
  return (
    <Box
      height={ height }
    >
      <ResponsiveBar
        data={ chartQuery.data }
        indexBy='id'
        keys={ [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ] }

        margin={ { top: 50, right: 20, bottom: hasAngledLabels ? 75 : 60, left: 65 } }

        theme={ {
          background: theme.palette?.background?.default,
          axis: {
            legend: { text: { fontSize: 18 } },
            ticks: { text: { fontSize: 14 } },
          },
          labels: { text: { fill: '#333' } },
          legends: { text: { fontSize: 14 } },
          text: { color: theme.palette.mode === 'dark' ? '#ccc' : '#333' },
          tooltip: {
            container: {
              backgroundColor: theme.palette?.background?.default,
              color: theme.palette.mode === 'dark' ? '#ccc' : '#333',
            },
          },
        } }

        groupMode='stacked'
        padding={ 0.25 }

        colors={ { scheme: 'red_yellow_green', size: 5 } }

        enableLabel={ false }

        axisLeft={ { legend: 'New Ratings Count', tickSize: 5, tickPadding: 5, tickValues: 5, legendOffset: -45, legendPosition: 'middle' } }
        axisBottom={ {
          legend: title,
          legendPosition: 'middle',
          legendOffset: hasAngledLabels ? 55 : 40,
          tickRotation: hasAngledLabels ? -33 : 0,
          tickSize: 5,
        } }

        legends={ [ {
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemsSpacing: 0,
          dataFrom: 'keys',
        } ] }
      />
    </Box>
  );
}