/* eslint-disable @typescript-eslint/no-explicit-any  */
import { Deserializer, DeserializerOptions, Serializer, SerializerOptions } from '@dreamer2q/jsonapi-serializer';
// import { TreeDataProvider } from '@react-admin/ra-tree';
import { addMinutes } from 'date-fns';
import { get } from 'lodash';
import { stringify } from 'query-string';
import { DataProvider, DeleteManyResult, fetchUtils } from 'react-admin';
import { QueryClient } from '@tanstack/react-query';

export type HttpClient = ( url: RequestInfo, options?: fetchUtils.Options ) => Promise<{ status: any, headers: Record<string, any>, body: any, json: any }>;
export type DataProviderFunction = ( resourceMap: ResourceMap, url: string, queryClient: QueryClient, httpClient: HttpClient ) => DataProvider;
// export type TreeDataProviderFunction = ( resourceMap: ResourceMap, url: string, httpClient: HttpClient ) => DataProvider & TreeDataProvider;

const defaultHttpClient: HttpClient = ( url, options = {} ) => fetchUtils.fetchJson( url, options );

const deserialize = async ( data: any, map: ResourceMap, options?: DeserializerOptions ): Promise<any> => {
  const types = Object.keys( map );
  const valueForRelationship = ( rel: { id: string } ) => rel.id;
  const relationships = Object.fromEntries( types.map( type => [ type, { valueForRelationship } ] ) );
  return new Deserializer( {
    keyForAttribute: 'camelCase',
    ...relationships,
    ...options,
  } ).deserialize( data )
}

const serialize = async ( resource: string, data: any, map: ResourceMap, options?: SerializerOptions ) => { // : Promise<any> => {
  const { attributes, relationships: relationshipFieldNames } = map[ resource ];
  const relationships = Object.fromEntries( relationshipFieldNames.map( field => [ field, { ref: true } ] ) )
  const body = await new Serializer( resource, {
    keyForAttribute: 'camelCase',
    pluralizeType: true,
    attributes,
    ...relationships,

    // hack
    typeForAttribute: type => {
      switch( type ) {
        case 'parent':
        case 'children':
          return resource;
        case 'amenityTags':
          return 'tags';
        case 'images':
          return 'images';
        default:
          return;
      }
    },

    ...options,
  } ).serialize( data );
  return JSON.stringify( body );
}

export interface ResourceDefinition {
  attributes: string[];
  relationships: string[];
}
export type ResourceMap = Record<string, ResourceDefinition>;

const getValidUntil = (): Date => addMinutes( new Date(), 7 );

type JSONAPI_ENTITY = { id: string, type: string, attributes: Record<string, any>, links: Record<string, string> };

export const jsonapiDataProvider: DataProviderFunction = ( resourceMap, apiUrl, queryClient, httpClient = defaultHttpClient ) => {

  const addIncludedToCache = async ( json: { included: JSONAPI_ENTITY[] | undefined } ): Promise<void> => {
    const included = get( json, 'included', [] );
    // console.log( 'included', included.length );
    included.forEach( async ( data ) => {
      const { type: resource, id } = data;
      const doc = await deserialize( { data }, resourceMap );
      queryClient.setQueryData( [ resource, 'getOne', { id } ], doc )
      queryClient.setQueryData( [ resource, 'getMany', { ids: [ id ] } ], [ doc ] )
    } );
  };

  return {
    getList: async ( resource, params ) => {
      const { page, perPage } = params.pagination || {};
      const { field, order } = params.sort || {};
      const query: Record<string, any> = {
        sort: ( order?.toUpperCase().slice( 0, 4 ) === 'DESC' ? '-' : '' ) + field,
        'page[number]': page,
        'page[size]': perPage,
      };
      Object.keys( params.filter || {} ).forEach( ( key ) => {
        const value = params.filter[ key ];
        query[ `filter[${ key }]` ] = Array.isArray( value ) ? value.join( ',' ) : value;
      } );
      const url = `${ apiUrl }/${ resource }?${ stringify( query ) }`;
      const { json } = await httpClient( url );
      const data = await deserialize( json, resourceMap );
      const { total = data.length } = json.meta;
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, total, json, validUntil };
    },

    getOne: async ( resource, params ) => {
      const { json } = await httpClient( `${ apiUrl }/${ resource }/${ params.id }` );
      const data = await deserialize( json, resourceMap );
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, validUntil };
    },

    getMany: async ( resource, params ) => {
      const query: Record<string, any> = {
        'filter[id]': ( params.ids || [] ).join( ',' ),
      };
      const url = `${ apiUrl }/${ resource }?${ stringify( query ) }`;
      const { json } = await httpClient( url );
      const data = await deserialize( json, resourceMap );
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, validUntil };
    },

    getManyReference: async ( resource, params ) => {
      const { id, target } = params;
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query: Record<string, any> = {
        sort: ( order === 'ASC' ? '' : '-' ) + field,
        'page[number]': page,
        'page[size]': perPage,
      };
      Object.keys( params.filter || {} ).forEach( ( key ) => {
        query[ `filter[${ key }]` ] = params.filter[ key ];
      } );
      if( target && id ) {
        query[ `filter[${ params.target }]` ] = id;
      }
      const url = `${ apiUrl }/${ resource }?${ stringify( query ) }`;
      const { json } = await httpClient( url );
      const data = await deserialize( json, resourceMap );
      const { total = data.length } = json.meta;
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, total, validUntil };
    },

    update: async ( resource, params ) => {
      const { id } = params;
      const body = await serialize( resource, { id, ...params.data }, resourceMap, {
      } );
      const url = `${ apiUrl }/${ resource }/${ params.id }`;
      const { json } = await httpClient( url, { method: 'PATCH', body } );
      const data = await deserialize( json, resourceMap );
      return { data };
    },

    updateMany: async ( resource, params ) => {
      const query = {
        filter: JSON.stringify( { id: params.ids } ),
      };
      const body = await serialize( resource, params.data, resourceMap, {
      } );
      const url = `${ apiUrl }/${ resource }?${ stringify( query ) }`;
      const { json } = await httpClient( url, { method: 'PATCH', body } );
      const data = await deserialize( json, resourceMap );
      return { data };
    },

    create: async ( resource, params ) => {
      const body = await serialize( resource, params.data, resourceMap, {
      } );
      const url = `${ apiUrl }/${ resource }`;
      const { json } = await httpClient( url, { method: 'POST', body } );
      const data = await deserialize( json, resourceMap );
      return { data };
    },

    delete: async ( resource, params ) => {
      const url = `${ apiUrl }/${ resource }/${ params.id }`;
      const { json } = await httpClient( url, { method: 'DELETE' } );
      const data = await deserialize( json, resourceMap );
      return { data };
    },

    deleteMany: async ( resource, params ) => {
      const data: DeleteManyResult[ 'data' ] = [];
      for( const id of params.ids ) {
        const url = `${ apiUrl }/${ resource }/${ id }`;
        const { json } = await httpClient( url, { method: 'DELETE' } );
        data.push = await deserialize( json, resourceMap );
      }
      return { data };
    },
  };
}
