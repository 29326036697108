import { Assessment, Autorenew, BarChart, RateReview, StarHalf, ViewList } from '@mui/icons-material';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { Layout, LayoutProps } from '@react-admin/ra-enterprise';
import { IconMenu } from '@react-admin/ra-navigation';
import { FC } from 'react';
import { AppBar, AppBarProps, MenuProps } from 'react-admin';
import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { AuthPage } from './AuthPage';

const MyAppBar: FC<AppBarProps> = props => (
  <AppBar { ...props }
  />
);

const MyMenu: FC<MenuProps> = props => (
  <IconMenu { ...props }
  >
    <IconMenu.Item key='demo' name='demo' to='/demo' label='Demo' icon={ <Assessment /> } />
    <IconMenu.Item key='subjects' name='subjects' to='/subjects' label='Subjects' icon={ <ViewList /> } />
    <IconMenu.Item key='jobs' name='jobs' to='/jobs' label='Jobs' icon={ <Autorenew /> } />
    <IconMenu.Item key='platforms' name='platforms' to='/platforms' label='Platforms' icon={ <RateReview /> } />
    <IconMenu.Item key='reviews' name='reviews' to='/reviews' label='Reviews' icon={ <StarHalf /> } />
    <IconMenu.Item key='visualizations' name='visualizations' to='/visualizations' label='Visualizations' icon={ <BarChart /> } />
  </IconMenu>
);

const MyLayout: FC<LayoutProps> = props => {
  const { children, ...rest } = props;
  const sessionContext = useSessionContext();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  if( sessionContext.loading ) {
    return <CircularProgress />;
  }

  if( !sessionContext.doesSessionExist ) {
    return <AuthPage />
  }

  return (
    <SessionAuth >
      <Box
        sx={ {
          '& .RaLayout-contentWithSidebar .RaLayout-content': {
            paddingBottom: '2rem', // adjust for AppFooter
          },
          '& .RaSolarLayout-contentWithSidebar .RaLayout-content': {
            paddingBottom: '2rem', // adjust for AppFooter
          },
          '& .RaSolarLayout-contentWithSidebar': {
            paddingTop: '2.7rem', // adjust for AppBar
          },
          '& #main-content': {
            paddingBottom: isXSmall ? '14rem' : '10rem',
          },
        } }
      >
        <Layout
          { ...rest }
          appBar={ MyAppBar }
          menu={ MyMenu }
        >
          { children }
        </Layout>
      </Box >
    </SessionAuth>
  );
};

export default MyLayout;
