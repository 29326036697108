import { common } from '@mui/material/colors';
import { deepmerge } from '@mui/utils';
import { defaultDarkTheme, defaultLightTheme, RaThemeOptions } from 'react-admin';

export const aicBlue = '#2e4766';
export const backgroundLight = '#fcfcfe';
// export const backgroundDark = '#030301';

const sharedTheme: RaThemeOptions = {
  typography: {
    fontSize: 16,
  },
}


export const darkTheme: RaThemeOptions = deepmerge( defaultDarkTheme, deepmerge( sharedTheme, {
  palette: {
    primary: {
      main: '#90caf9',
      dark: '#90caf9',
    },
    secondary: {
      main: aicBlue,
      light: aicBlue,
      dark: aicBlue,
    },
    background: {
      // default: backgroundDark,
    },
  },
} ) );

export const lightTheme: RaThemeOptions = deepmerge( defaultLightTheme, deepmerge( sharedTheme, {
  palette: {
    primary: {
      main: '#4f3cc9',
      light: '#4f3cc9', // '#0059d6',
    },
    secondary: {
      main: aicBlue,
      light: aicBlue,
      dark: aicBlue,
      contrastText: common.white,
    },
    background: {
      default: backgroundLight,
    },
  },
} ) );
