import { Box, Chip } from '@mui/material';
import { Datagrid, Edit, List, Show } from 'react-admin';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { ArrayField, ArrayInput, AutocompleteArrayInput, ChipField, Create, CreateProps, EditProps, FunctionField, ListProps, NumberInput, RadioButtonGroupInput, RaRecord, ReferenceArrayField, ReferenceArrayInput, ReferenceInput, ShowProps, SimpleForm, SimpleFormIterator, SimpleShowLayout, SingleFieldList, TextField, TextInput, useGetIdentity, useRecordContext } from 'react-admin';

import { apiUrl, httpClient } from './DataProvider';
import { SubjectReviewChart } from './SubjectReviews';

export { default as VisualizationIcon } from '@mui/icons-material/BarChart';

export const VisualizationList: FC<ListProps> = props => {

  const { identity, isLoading } = useGetIdentity();
  const userId = identity?.id;

  if( isLoading ) return <></>;
  return (
    <List { ...props }
      exporter={ false }
      filter={{ userId }}
    >
      <Datagrid
        rowClick='show'
        expand={ <VisualizationExpandShow /> }
      >
        <TextField source="title" />
        <TextField source="id" />
      </Datagrid>
    </List>

  )
};

export const VisualizationExpandShow: FC = () => {
  return (
    <ArrayField source="charts" >
      <Datagrid
        bulkActionButtons={ false }
      >
        <TextField source="title" />
        <ReferenceArrayField source="subjects" reference="subjects">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ArrayField source="organizations">
          <SingleFieldList>
            <FunctionField render={ ( org ) => (
              <Chip label={ org } sx={{ margin: 0.5 }} />
            ) }/>
          </SingleFieldList>
        </ArrayField>
        <ReferenceArrayField source="platforms" reference="platforms">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          source="duration"
          render={ ( record: RaRecord ) => `${record.duration.count} ${record.duration.type}`}
        />
      </Datagrid>
    </ArrayField>
  );
}

const VisualizationTitle: FC = () => {
  const record = useRecordContext();
  if( !record ) return null;
  return <span>Visualization "{ record.title }"</span>
}

export const VisualizationShow: FC<ShowProps> = props => {
  return (
    <Show {...props} title={ <VisualizationTitle /> }>
      <SimpleShowLayout>
        <TextField source="title" />
        <ArrayField source="charts">
          <SingleFieldList linkType={ false }>
            <FunctionField render={ ( record: RaRecord ) => (
              <Box width={ 500 } boxShadow={ 3 } borderRadius={ 2 } margin= { 1 } >
                <SubjectReviewChart
                  { ...record }
                  duration={ record.duration }
                />
              </Box>
            ) } />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
}

export const VisualizationForm: FC = () => {
  const [ options, setOptions ] = useState<Record<string,{id:string}[]>>();

  useEffect( () => { ( async () => {
    const url = new URL( `${apiUrl}/subjects/search` );
    const response = await httpClient( url.href );
    if( response.status !== 200 ) return;
    const result = _.mapValues( response.json, values => values.map( ( v:string ) => ( { id: v, name: v } ) ) )
    setOptions( result );
  } )() }, [] );

  return (
    <SimpleForm>
      <TextInput source="title" />
      <ArrayInput source="charts" >
        <SimpleFormIterator>
          <TextInput source="title" label="Chart Title" />
          <NumberInput source="duration.count" label="Duration" min={1}/>
          <RadioButtonGroupInput
            source="duration.type"
            label=""
            choices={[
              { id: 'months', name: 'Months' },
              { id:'years', name: 'Years' },
            ]}
          />
          <ReferenceArrayInput source='subjects' reference='subjects'><AutocompleteArrayInput/></ReferenceArrayInput>
          <AutocompleteArrayInput source='organizations' choices={ options?.organizations } />
          <AutocompleteArrayInput source='kinds' choices={ options?.kinds } />
          <ReferenceInput source='platforms' reference='platforms'><AutocompleteArrayInput/></ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
}

export const VisualizationEdit: FC<EditProps> = props => {
  const transform = ( data: RaRecord ): RaRecord => ( {
    ...data,
    charts: _.map( data.charts, chart => _.pickBy( chart, x => x ) ),
  } );
  return (
    <Edit {...props}
      actions={ undefined }
      title={ <VisualizationTitle /> }
      transform={ transform }
    >
      <VisualizationForm />
    </Edit>
  )
};

export const VisualizationCreate: FC<CreateProps> = props => {
  const { identity, isLoading } = useGetIdentity();
  const userId = identity?.id;

  const transform = ( data: RaRecord ): RaRecord => ( {
    ...data,
    charts: _.map( data.charts, chart => _.pickBy( chart, x => x ) ),
    userId,
  } );

  if( isLoading ) return <></>;
  return (
    <Create {...props}
      transform={ transform }
    >
      <VisualizationForm />
    </Create>
  )
};
