import { getTimeZones } from '@vvo/tzdb';
import { titleize } from 'inflection';
import _ from 'lodash';
import { DataProvider, fetchUtils } from 'react-admin';
import { jsonapiDataProvider, ResourceMap } from './jsonapi-data-provider';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient( {
  defaultOptions: {
    queries: {
      staleTime: 7 * 60 * 1000, // 7 mins
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
} );

const {
  REACT_APP_API_SERVER: apiServer = 'http://localhost:3000',
  REACT_APP_API_PATH: apiPath = '/api/v1',
  REACT_APP_ACCEPT_HEADER: accept = 'application/vnd.api+json',
} = process.env;
export const apiUrl = `${ apiServer }${apiPath}`;

// http -b :3400/api/v1/spec | jq '[ .paths | to_entries[] | select( .key | match( "/{id}" ) ) | .value = ( .value.patch.requestBody.content | to_entries[0].value | .schema.properties.data | to_entries[0].value | { attributes: ( .. | .attributes?.properties | select( . ) | keys ), relationships: ( .. | .relationships?.properties | select( . ) | keys ) } ) | .key = ( .key[1:-5] ) ] | sort_by( .key ) | from_entries'

const resourceMap: ResourceMap = {
  jobs: {
    attributes: [ 'error', 'profile', 'status', 'completedAt', 'createdAt', 'updatedAt' ],
    relationships: [ 'profile' ],
  },
  platforms: {
    attributes: [ 'name', 'profileUrlTemplate', 'referralUrlTemplate', 'logo' ],
    relationships: [],
  },
  profiles: {
    attributes: [ 'resource', 'platform', 'url' ],
    relationships: [ 'platform' ],
  },
  reviews: {
    attributes: [ 'author', 'body', 'publishedAt', 'rating', 'response', 'createdAt', 'updatedAt' ],
    relationships: [ 'profile' ],
  },
  subjectreviews: {
    attributes: [ 'author', 'body', 'publishedAt', 'rating', 'response', 'reference', 'organization', 'kind', 'name', 'address', 'phone', 'isClaimed', 'createdAt', 'updatedAt' ],
    relationships: [ 'platform', 'profile', 'review', 'subject' ],
  },
  subjects: {
    attributes: [ 'name', 'reference', 'organization', 'kind', 'profiles' ],
    relationships: [ 'profiles' ],
  },
  summaries: {
    attributes: [ 'rating', 'count', 'distribution', 'createdAt', 'updatedAt' ],
    relationships: [ 'profile' ],
  },
  visualizations: {
    attributes: [ 'charts', 'title', 'userId', 'createdAt', 'updatedAt' ],
    relationships: [],
  },
};

// http -b :3400/api/v1/spec | jq -rc ' [ .. | .enum? | select(.) ] | unique | sort_by( . | length ) | .[]'
export const options: Record<string, string[]> = {
};

export const choices: Record<keyof typeof options, { id: string, name: string }[]> = _( options ).mapValues( a => a.map( s => ( { id: s, name: titleize( s ) } ) ) ).value();
choices.timeZoneName = _( getTimeZones()
  .filter( tz => tz.countryCode === 'US' )  // start with USA
  .flatMap( tz => tz.group.map( tg => ( { id: tg, name: tg } ) ) ) )
  .sortBy( 'name' )
  .value();

export const delay = async ( ms: number ): Promise<void> => new Promise( resolve => setTimeout( resolve, ms ) );

export const httpClient = fetchUtils.fetchJson;

const dataProvider = jsonapiDataProvider( resourceMap, apiUrl, queryClient, httpClient );

const myDataProvider: DataProvider = {
  ...dataProvider,
  fetchJson: httpClient,
  apiUrl,
}

export { myDataProvider as dataProvider };
export default myDataProvider;
