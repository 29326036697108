/*eslint @typescript-eslint/no-unused-vars: "warn"*/
// import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Admin } from '@react-admin/ra-enterprise';
import { FC, useMemo } from 'react';
import { CustomRoutes, Resource } from 'react-admin';
import * as reactRouterDom from 'react-router-dom';
import { Route } from 'react-router-dom';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';

import { AggregateReportPage } from './AggregateReport';
import { MyAuthPage, SupertokensUiCustomization } from './AuthPage';
import dataProvider from './DataProvider';
import { DemoPage } from './Demo';
import i18nProvider from './i18nProvider';
import { JobList } from './Jobs';
import MyLayout from './MyLayout';
import { PlatformEdit, PlatformList } from './Platforms';
import { ProfileShow } from './Profiles';
import { ReviewList } from './Reviews';
// import { SubjectReviewIcon, SubjectReviewList } from './SubjectReviews';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui.js';
import { SubjectCreate, SubjectList, SubjectShow } from './Subjects';
import { SupertokensProvider } from './SupertokensProvider';
import { darkTheme, lightTheme } from './theme';
import { TrendPage } from './Trends';
import { VisualizationCreate, VisualizationEdit, VisualizationList, VisualizationShow } from './Visualizations';

const App: FC = () => {
  const authProvider = useMemo( () => new SupertokensProvider(), [] );

  return (
    <SuperTokensWrapper>
      <SupertokensUiCustomization>
        <Admin
          title="AIC Reputation Admin"
          authProvider={ authProvider }
          dataProvider={ dataProvider }
          requireAuth
          i18nProvider={ i18nProvider }
          layout={ MyLayout }
          lightTheme={ lightTheme }
          darkTheme={ darkTheme }
          disableTelemetry
          loginPage={ <MyAuthPage />}
        >

          <Resource name="demo"
            list={ DemoPage }
          />
          <Resource name="subjects"
            list={ SubjectList }
            create={ SubjectCreate }
            show={ SubjectShow }
          />
          <Resource name="jobs"
            list={ JobList }
          />
          <Resource name="platforms"
            options={ { label: 'Platforms' } }
            list={ PlatformList }
            edit={ PlatformEdit }
          />
          <Resource name="profiles"
            show={ ProfileShow }
          />
          <Resource name="reviews"
            list={ ReviewList }
          />
          {/* <Resource name="subjectreviews"
          list={ SubjectReviewList }
          options={{ label: 'Reviews' }}
        /> */}
          <Resource name="summaries" />
          <Resource name="visualizations"
            list={ VisualizationList }
            show={ VisualizationShow }
            create={ VisualizationCreate }
            edit={ VisualizationEdit }
          />
          <CustomRoutes>
            { getSuperTokensRoutesForReactRouterDom( reactRouterDom, [ EmailPasswordPreBuiltUI, PasswordlessPreBuiltUI ] ) }
            <Route path='/auth' element={ <MyAuthPage /> } />
            <Route path='/login' element={ <MyAuthPage /> } />
            <Route path='/trends' element={ <TrendPage /> } />
            <Route path='/aggregatereport' element={ <AggregateReportPage /> } />
          </CustomRoutes>
        </Admin>
      </SupertokensUiCustomization>
    </SuperTokensWrapper>
  );
}


export default App;
