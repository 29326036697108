import { green, lightGreen, red, yellow } from '@mui/material/colors';
import _ from 'lodash';
import { FC } from 'react';
import { Datagrid, DateField, Filter, List, ListProps, RaRecord, ReferenceField, ReferenceManyField, SelectInput, SimpleShowLayout, SingleFieldList, TextField } from 'react-admin';

export { default as JobIcon } from '@mui/icons-material/Autorenew';

const JobFilters: FC = ( props ) => {
  const statusChoices = [
    'In Progress',
    'Complete',
    'Failed',
  ].map( c => ( { id: c, name: c } ) );
  return (
    <Filter { ...props } >
      <SelectInput source="status" choices={ statusChoices } />
    </Filter>
  );
};
export const JobList: FC<ListProps> = props => {
  const jobRowStyle = ( { status }: RaRecord ): Record<string, unknown> => {
    const colorMap = {
      'Complete': green[ '700' ],
      'In Progress': yellow[ '500' ],
      'Deferred': lightGreen[ '500' ],
      'Failed': red[ '700' ],
    };
    return { borderLeft: `solid 10px ${ _.get( colorMap, status ) }` };
  };
  return (
    <List {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={ <JobFilters /> }
      exporter={ false }
      queryOptions={{ refetchInterval: 5000 }}
    >
      <Datagrid
        bulkActionButtons={ false }
        expand={ <JobExpandShow /> }
        isRowExpandable={ ( record: RaRecord ) => record.error }
        rowStyle={ jobRowStyle }
      >
        <TextField source='profile' />
        <ReferenceField label='Display Name' reference='profiles' source='profile' link={false}>
          <ReferenceManyField
            reference='summaries' target='profile'
            sort={{ field: 'createdAt', order: 'DESC' }}
            page={1} perPage={1}
          >
            <SingleFieldList linkType={ false }>
              <TextField source='name' />
            </SingleFieldList>
          </ReferenceManyField>
        </ReferenceField>
        <ReferenceField label='Platform' reference='profiles' source='profile' link={false}>
          <ReferenceField reference='platforms' source='platform' link={false} >
            <TextField source='name' />
          </ReferenceField>
        </ReferenceField>
        <TextField source='status' />
        <DateField label='Start Date' source='createdAt' showTime={ true } options={{ dateStyle: 'short', timeStyle: 'short' }} />
        <DateField label='Finish Date' source='completedAt' showTime={ true } options={{ dateStyle: 'short', timeStyle: 'short' }} />
      </Datagrid>
    </List>
  );
}

const JobExpandShow: FC = ( props ) => (
  <SimpleShowLayout>
    <TextField source='error' label='Error Message' />
  </SimpleShowLayout>
);