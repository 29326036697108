import LaunchIcon from '@mui/icons-material/Launch';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Link, LinkProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Identifier, RaRecord, useDataProvider, useGetList, useNotify } from 'react-admin';
import { lookupProfile, ProfileReviewChart } from './Profiles';
import { apiUrl, httpClient } from './DataProvider';

export { default as DemoIcon } from '@mui/icons-material/Assessment';

const DisplayField: FC<{ value: string, label: string, id?: string }> = ( props ) => {
  const theme = useTheme();
  const textColor = theme.palette?.mode === 'dark' ? '#ccc' : '#333';
  return (
    <TextField
      id={ props.id }
      value={ props.value }
      label={ props.label }
      variant='outlined'
      disabled
      multiline
      fullWidth
      sx={{
        '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: textColor }, // styles the value
        '& .MuiInputLabel-root': { color: 'text.primary' }, // styles the label
        margin: 1,
        display: 'block',
        width: '50%',
        minWidth: '400px',
      }}
    />
  );
}

interface ExportFormProps {
  id?: string;
  callback: ( email: string ) => Promise<void>;
  disabled?: boolean;
}
const ExportFormDialog: FC<ExportFormProps> = ( props ) => {
  const [ open, setOpen ] = useState( false );
  const [ email, setEmail ] = useState( '' );

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );
  const handleSubmit = async () => {
    await props.callback( email );
    handleClose();
  };

  return (
    <div>
      <Button
        id={ `${props.id}_button` }
        variant="outlined"
        onClick={ handleClickOpen }
        disabled={ props.disabled }
        sx={{ marginLeft: '2em' }}
      > Email </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Email</DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
          <TextField
            autoFocus
            margin="dense"
            id={ `${props.id}_email` }
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={ email }
            onChange={ e => setEmail( e.target.value ) }
          />
        </DialogContent>
        <DialogActions>
          <Button
            id={ `${props.id}_cancel` }
            onClick={handleClose}
            color='error'
          >Cancel</Button>
          <Button
            id={ `${props.id}_submit` }
            onClick={handleSubmit}
          >Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const useStyles = makeStyles( {
  link: {
    textDecoration: 'none',
  },
  icon: {
    width: '0.5em',
    height: '0.5em',
    paddingLeft: 2,
  },
} );
const prettyUrlLabel = ( url: string ): string => {
  return url
    .replace( /http(s)?:\/\/(www.)?/, '' )
    .replace( /\/.*/, '' )
    .toLowerCase()
    .split( '.' )
    .slice( -2, -1 )
    .join( '.' );
}
const PlatformLink: FC<LinkProps> = ( props => {
  const { href: value } = props;
  const classes = useStyles();
  if( !value ) {
    return null;
  }

  return (
    <Link
      className={ classes.link }
      href={ value }
      rel="noopener"
      target="_blank"
      onClick={ e => { e.stopPropagation(); } }
    >
      <Button style={ {
        whiteSpace: 'nowrap',
        fontSize: '14px',
      } } >
        { prettyUrlLabel( value ) }
        <LaunchIcon className={ classes.icon } fontSize="small" />
      </Button>
    </Link>
  );
} );


export const DemoPage: FC = ( props ) => {
  const pollingMilliseconds = 1000 * 5;
  const dataProvider = useDataProvider();
  const [ urlInput, setUrlInput ] = useState<string>( '' );
  const [ url, setUrl ] = useState<string>( '' );
  const [ profileId, setProfileId ] = useState<Identifier>( '' );
  const [ profile, setProfile ] = useState<RaRecord>();
  const [ job, setJob ] = useState<RaRecord>();
  const [ summary, setSummary ] = useState<RaRecord>();
  const { data: platforms = [] } = useGetList<RaRecord>( 'platforms' );
  const notify = useNotify();

  const updateUrl = () => {
    setSummary( undefined );
    setJob( undefined );
    setProfile( undefined );
    setProfileId( '' );
    setUrl( urlInput );
  }

  const updateProfileId = useCallback( async ( profileUrl: string ) => {
    try {
      const id = await lookupProfile( new URL( profileUrl ).href );
      if( id ) {
        setProfileId( id );
      } else {
        notify( 'errors.parsingUrl', { type: 'error' } );
        setUrl( '' );
      }
    } catch ( e ) {
      console.error( e );
      notify( 'errors.parsingUrl', { type: 'error' } );
      setUrl( '' );
    }
  }, [ setProfileId ] );
  useEffect( () => {
    if( url ) updateProfileId( url );
  }, [ updateProfileId, url, setUrl ] );

  const updateProfile = useCallback( async ( id: Identifier ) => {
    const { data } = await dataProvider.getOne( 'profiles', { id } );
    if( data ) setProfile( data );
  }, [ dataProvider, setProfile ] );
  const updateJob = useCallback( async ( profileId: Identifier ) => {
    const { data } = await dataProvider.getList( 'jobs', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { profile: profileId },
    } );
    if( data.length ) setJob( data[0] );
  }, [ dataProvider, setJob ] )
  useEffect( () => {
    if( !profileId ) return;
    updateProfile( profileId );
    updateJob( profileId );
  }, [ profileId, updateJob, updateProfile ] );
  useEffect( () => {
    if( !profileId ) return;
    const i = setInterval( () => {
      updateProfile( profileId );
    }, pollingMilliseconds );
    return () => clearInterval( i );
  }, [ pollingMilliseconds, profile, profileId, updateProfile ] );
  useEffect( () => {
    if( !profileId ) return;
    const i = setInterval( () => {
      updateJob( profileId );
    }, pollingMilliseconds );
    return () => clearInterval( i );
  }, [ pollingMilliseconds, job, profileId, updateJob ] );

  const updateSummary = useCallback( async ( profileId: Identifier ) => {
    const { data } = await dataProvider.getList( 'summaries', {
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { profile: profileId },
    } );
    if( data.length ) setSummary( data[0] );
  }, [ dataProvider, setSummary ] );
  useEffect( () => {
    if( profileId ) updateSummary( profileId );
  }, [ profileId, updateSummary ] )
  useEffect( () => {
    if( !profileId ) return;
    const i = setInterval( () => {
      updateSummary( profileId );
    }, pollingMilliseconds );
    return () => clearInterval( i );
  }, [ pollingMilliseconds, profileId, summary, updateSummary ] );

  const isScanning = useMemo( () => !!url && ![ 'Complete', 'Failed', 'Deferred' ].includes( job?.status ), [ url, job ] );

  const getPlatformNameById = useCallback( ( id: string ): string => {
    return platforms.find( p => p.id === id )?.name ?? id
  }, [ platforms ] );

  const handleExport = useCallback( async ( email: string ) => {
    const body = {
      kind: 'profile',
      id: profileId,
      recipients: email.split( /[,;\s]+/ ),
    };
    await httpClient( `${ apiUrl }/reports`, {
      method: 'POST',
      body: JSON.stringify( body ),
    } );
  }, [ profileId ] )

  return (
    <Card {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="resourceInput"
          label="Profile Link"
          required
          value={ urlInput }
          onChange={ e => setUrlInput( e.target.value ) }
          sx={{ width: '50%', minWidth: '400px', margin: 1 }}
        />
        <LoadingButton
          id='scanButton'
          loading={ isScanning }
          onClick={ updateUrl }
          variant='outlined'
          disabled={ urlInput === url }
          sx={{ marginLeft: '2em' }}
        >Scan</LoadingButton>
        <Button
          id='clearButton'
          onClick={ () => {
            setUrlInput( '' );
            updateUrl();
            setUrl( '' );
          } }
          variant='outlined'
          disabled={ !urlInput }
          sx={{ marginLeft: '2em' }}
        >Clear</Button>
        <ExportFormDialog
          id='export'
          callback={ handleExport }
          disabled={ !summary }
        />
      </div>
      { ( !urlInput )
        ? (
          <div>
            <PlatformLink href='https://maps.google.com/' />
            <PlatformLink href='https://www.yelp.com/search' />
          </div>
        )
        : null }
      { ( profileId )
        ? (
          <DisplayField
            id='scanStatus'
            label='Scan Status'
            value={ job?.status || 'Unknown' }
          />
        )
        : null }
      { ( profile )
        ? (
          <DisplayField
            id='platform'
            label='Platform'
            value={ getPlatformNameById( profile.platform ) }
          />
        )
        : null }
      { ( summary?.name )
        ? (
          <DisplayField
            id='name'
            label='Name'
            value={ summary.name }
          />
        )
        : null }
      { ( summary?.address )
        ? (
          <DisplayField
            id='address'
            label='Address'
            value={ summary.address }
          />
        )
        : null }
      { ( summary?.rating )
        ? (
          <DisplayField
            id='ratingValue'
            label='Average Rating'
            value={ summary.rating }
          />
        )
        : null }
      { ( summary?.count )
        ? (
          <DisplayField
            id='ratingCount'
            label='Ratings'
            value={ summary.count }
          />
        )
        : null }
      { ( summary?.count )
        ? (
          <div>
            <Box width={ 500 } boxShadow={ 3 } margin={ 1 } display='inline-block' >
              <ProfileReviewChart
                profileId={ profileId }
                duration={{
                  type: 'months',
                  count: 6,
                }}
                refreshMillis={ pollingMilliseconds }
                title={ `${summary?.name} Reviews by Month` }
              />
            </Box>
            <Box width={ 500 } boxShadow={ 3 } margin={ 1 } display='inline-block' >
              <ProfileReviewChart
                profileId={ profileId }
                duration={{
                  type: 'years',
                  count: 5,
                }}
                refreshMillis={ pollingMilliseconds }
                title={ `${summary?.name} Reviews by Year` }
              />
            </Box>
          </div>
        )
        : null }
    </Card>
  );
}